import React, { useState, useEffect } from 'react';
import './TypingEffect.css';

function TypingEffect() {
  const phrases = ["Full Stack Web Dev", "Dev Ops Engineer", "Data Scientist", "Cloud Architect", "AI/ML Engineer"];
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);

    useEffect(() => {
        let timeout;
    
        if (subIndex === phrases[index].length && !reverse) {
            // Start deleting text when the end of the phrase is reached
            timeout = setTimeout(() => setReverse(true), 1000); // Add a delay before deleting
        } else if (subIndex === 0 && reverse) {
            // Start typing the next text when the current text is fully deleted
            setReverse(false);
            setIndex((index + 1) % phrases.length);
        } else {
            timeout = setTimeout(() => {
                setSubIndex(subIndex + (reverse ? -1 : 1));
            }, reverse ? 50 : 100);
        }
    
        return () => clearTimeout(timeout);
        // eslint-disable-next-line
    }, [subIndex, index, reverse]);
    


  return (
    <div className="typing-container">
      {`${phrases[index].substring(0, subIndex)}${subIndex === phrases[index].length || reverse ? "" : "|"}`}
    </div>
  );
}

export default TypingEffect;
