import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import resume from '../resume.pdf';
import ref from '../ref-letter.pdf';
import resumepic from '../resume.png';
import refpic from '../ref.png';

function Resume() {
  const [documentType, setDocumentType] = useState(null);

  const viewDocument = (docType) => {
    setDocumentType(docType);
  };

  const renderDocument = () => (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div>
        <Viewer fileUrl={documentType === 'resume' ? resume : ref} />
      </div>
    </Worker>
  );

  return (
    <div className="main-content">
      <div className="document-display">
        {documentType === 'resume' && (
          <div id="documentViewer">
            {renderDocument()}
          </div>
        )}
        <div className="document-item">
          {documentType !== 'resume' && <img src={resumepic} alt="Resume Thumbnail" id="resume-thumbnail" />}
          <div className="button-container">
            
            <button onClick={() => viewDocument('resume')} class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">View Resume</span>
          </button>

          <a href={resume} download="Kirolos_Youssef_Resume.pdf" class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Download</span>
          </a>
          </div>
        </div>
      </div>
      <div className="document-display">
        {documentType === 'refLetter' && (
          <div id="documentViewer">
            {renderDocument()}
          </div>
        )}
        <div className="document-item">
          {documentType !== 'refLetter' && <img src={refpic} alt="Reference Letter Thumbnail" id="reference-letter-thumbnail" />}
          <div className="button-container">
          <button onClick={() => viewDocument('refLetter')} class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">View Letter</span>
          </button>

          <a href={ref} download="Kirolos_Youssef_Reference_Letter.pdf" class="learn-more">
            <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
            </span>
            <span class="button-text">Download</span>
          </a>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Resume;
