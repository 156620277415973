import React, {useState} from 'react';
import axios from 'axios';

function Contact() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    files: [],
  });
 
  const handleChange = (e) => {
    const {name, value, files } = e.target;
    if (name === 'attachments' && files) {
      const newFiles = Array.from(files);
    setFormData(prev => ({
      ...prev,
      files: [...prev.files, ...newFiles],
    }));
  } else {
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDeleteFile = (index) => {
    setFormData(prev => ({
        ...prev,
        files: prev.files.filter((_, i) => i !== index)
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      if (key === 'files') {
        formData[key].forEach(file => data.append('files',file));
      } else {
        data.append(key, formData[key]);
      }
    }
    // https://my-portfolio-server-delta.vercel.app
    try {
      const response = await axios.post('https://www.kirolosyoussef.com/api/contact', data, {
        timeout: 15000, // Set a timeout of 15 seconds (adjust as needed)
      });
      if (response.status === 200) {
        alert ('Inquiry sent successfully');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
          files: [],
        });
        document.getElementById("file").value = "";
      } else {
        alert ('Failed to send Inquiry' + response.statusText);
      }
    } catch (error) {
      alert ('Error sending inquiry: ' + error.message);
    }
  };
    
  return (
    <div class="main-content">

        <div class="background">
          <div class="container">
            <div class="screen">
              <div class="screen-header">
                <div class="screen-header-left">
                  <div class="screen-header-button close"></div>
                  <div class="screen-header-button maximize"></div>
                  <div class="screen-header-button minimize"></div>
                </div>
                <div class="screen-header-right">
                  <div class="screen-header-ellipsis"></div>
                  <div class="screen-header-ellipsis"></div>
                  <div class="screen-header-ellipsis"></div>
                </div>
              </div>
              <div class="screen-body">
                <div class="screen-body-item left">
                  <div class="app-title">
                    <span>CONTACT ME</span>
                    {/* <span> ME</span> */}
                  </div>
                  {/* <div class="app-contact">CONTACT INFO : kirolos358@gmail.com</div> */}
                </div>
                <div class="screen-body-item">
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div class="app-form">
                    <div class="app-form-group">
                      <input class="app-form-control" placeholder="NAME" type="text" id="name" name="name" required value={formData.name} onChange={handleChange}/>
                    </div>
                    <div class="app-form-group">
                      <input class="app-form-control" placeholder="EMAIL" type="email" id="email" name="email" required value={formData.email} onChange={handleChange}/>
                    </div>
                    <div class="app-form-group">
                      <input class="app-form-control" placeholder="Phone Number" type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange}/>
                    </div>
                    <div class="app-form-group message">
                      <input class="app-form-control" placeholder="MESSAGE" id="message" name="message" required value={formData.message} onChange={handleChange}/>
                    </div>
                    <div class="app-form-group">
                    <label htmlFor="file" class="custum-file-upload">
                      <div class="icon">
                      <svg viewBox="0 0 24 24" fill="" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" fill=""></path> </g></svg>
                      </div>
                      <div class="text">
                        <span>Click to upload image</span>
                        </div>
                        <input type="file" id="file" name="attachments" onChange={handleChange} multiple/>
                    </label>
                    </div>
                    
                    {formData.files.length > 0 && (
                      <ul>
                        {formData.files.map((file, index) =>(
                          <div key={index} class="file-container">
                          <li>{file.name}
                          <button onClick={() => handleDeleteFile(index)} class="button">
                        <svg viewBox="0 0 448 512" class="svgIcon"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                      </button>
                      </li>
                      </div>
                        ))}                        
                      </ul>
                    )}
                    
                    <div class="app-form-group buttons">
                      <button class="app-form-button">CANCEL</button>
                      <button class="app-form-button" type="submit">SEND</button>
                    </div>                   
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Contact;
