import React from 'react';

function Footer() {
  return (
    <footer className="footer">
    <i>Designed and Developed by Kirolos Youssef</i>
    <p>Copyright © 2024 KY</p>
      
    </footer>
  );
}

export default Footer;